var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "b-row",
        [
          _c(
            "b-col",
            {
              staticClass: "lateral-home-left",
              attrs: { cols: "12", lg: "3" }
            },
            [
              _c("router-link", { attrs: { to: "/home" } }, [
                _c("img", {
                  staticClass: "img-logo-home",
                  attrs: {
                    src: require("@/assets/images/Logo_ProgreSER_W.png"),
                    alt: "Logo"
                  }
                })
              ])
            ],
            1
          ),
          _c(
            "b-col",
            { staticClass: "px-4 pb-5 mb-3", attrs: { cols: "12", lg: "9" } },
            [_c("Navbar"), _c("router-view")],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }