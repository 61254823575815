var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-row",
    [
      _c(
        "b-col",
        { attrs: { cols: "12" } },
        [
          _c(
            "b-navbar",
            {
              staticClass: "px-0 py-4 barra-nav",
              attrs: { toggleable: "lg", type: "light", variant: "link" }
            },
            [
              _c("b-navbar-toggle", {
                staticClass: "btn-nav",
                attrs: { target: "nav-collapse" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function() {
                      return [
                        _c("i", { staticClass: "las la-bars fs-35 info" })
                      ]
                    },
                    proxy: true
                  }
                ])
              }),
              _c(
                "b-collapse",
                { attrs: { id: "nav-collapse", "is-nav": "" } },
                [
                  _c(
                    "b-navbar-nav",
                    { staticClass: "ml-auto" },
                    [
                      _vm._l(_vm.orderMenu(_vm.menuOptions), function(
                        menuOption
                      ) {
                        return [
                          menuOption.hijosMenu.length === 0
                            ? _c(
                                "b-nav-item",
                                {
                                  key: menuOption.id,
                                  staticClass: "text-lg-center",
                                  attrs: {
                                    to:
                                      menuOption.external === "N"
                                        ? menuOption.url
                                        : null
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.isExternal(menuOption)
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        menuOption.nombreMenu.toUpperCase()
                                      ) +
                                      " "
                                  )
                                ]
                              )
                            : _c(
                                "b-nav-item-dropdown",
                                {
                                  key: menuOption.id,
                                  class:
                                    "text-lg-center " +
                                    (menuOption.id == 11
                                      ? "btn-sm btn-perfil content-center p-0"
                                      : ""),
                                  attrs: {
                                    right: "",
                                    text: menuOption.nombreMenu
                                  }
                                },
                                [
                                  _c("template", { slot: "button-content" }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          menuOption.nombreMenu.toUpperCase()
                                        ) +
                                        " "
                                    )
                                  ]),
                                  _vm._l(
                                    _vm.orderMenu(menuOption.hijosMenu),
                                    function(subMenuOption) {
                                      return [
                                        _c(
                                          "b-dropdown-item",
                                          {
                                            key: subMenuOption.id,
                                            attrs: { to: subMenuOption.url }
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  subMenuOption.nombreMenu.toUpperCase()
                                                ) +
                                                " "
                                            )
                                          ]
                                        )
                                      ]
                                    }
                                  )
                                ],
                                2
                              )
                        ]
                      })
                    ],
                    2
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-col",
        { staticClass: "content-end-center", attrs: { cols: "12" } },
        [
          _c(
            "p",
            { staticClass: "mb-0 fs-12 ultimo-acceso fw-bold text-right" },
            [
              _c("i", { staticClass: "las la-star danger" }),
              _vm._v(" " + _vm._s(_vm.ultimoIngreso))
            ]
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }